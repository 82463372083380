@import "~@/styles/variables/variables.scss";











































































































































































.device-list-box {
	.device-list-item {
		position: relative;
		width: 392px;
		padding: 9px 9px 14px 9px;
		background: #f3f8ff;
		border-radius: 4px;
		margin-top: 12px;
		.title {
			display: flex;
			justify-content: space-between;
			font-weight: 500;
			font-size: 16px;
			height: 24px;
			line-height: 24px;
			color: #333333;
			.left {
				.icon-box {
					display: inline-block;
					width: 25px;
					height: 25px;
					font-size: 16px;
					text-align: center;
					line-height: 25px;
					background: #d1d6f7;
					color: #4d6bff;
					border-radius: 4px;
					i {
						font-size: 22px;
					}
				}
				.text {
					margin: 0 8px;
				}
				.green {
					background-color: #29c287;
				}
				.red {
					background-color: #e5662e;
				}
				.orange {
					background-color: #ff9419;
				}
				.grey {
					background-color: #878eb2;
				}
			}

			.status {
				display: inline-block;
				height: 20px;
				padding: 0 8px;
				line-height: 20px;
				font-size: 12px;
				color: #ffffff;
				text-align: center;
				background: #29c287;
				border-radius: 15px;
			}
		}
		.content {
			margin: 10px 0 0 34px;
			font-size: 14px;
			line-height: 21px;
			color: #5c6073;
			li {
				display: flex;
				align-items: center;
				position: relative;
				color: #6b7399;
				margin-bottom: 4px;
				padding-left: 22px;
				i {
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			&-label {
				width: 85px;
				flex-shrink: 0;
			}
		}
		.check-box {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;
			.check-radio {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 1px solid #4d6bff;
			}
			.checked {
				background: url('~@/assets/imgs/icon/choose-icon.png') no-repeat center center;
			}
		}
	}
	.normal {
		background-color: #f3f8ff;
	}
	.warn {
		background-color: #f4f4f4;
	}
}

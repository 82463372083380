@import "~@/styles/variables/variables.scss";







































































































































































@import '../../runLayout.scss';
.continer-wrapper {
	height: 100%;
	padding: 20px;
	display: flex;
	overflow: hidden;
	.device-list {
		width: 412px;
		height: 100%;
		flex-shrink: 0;
		border-right: 0.5px dashed #999999;
		.device-box {
			height: calc(100% - 24px);
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
	.echarts-list {
		flex: 1;
		padding-left: 20px;
	}
	.right-box {
		padding: 25px 0 0 21px;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;
		&-title {
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
			padding-bottom: 20px;
		}
		.pressure-tap-search {
			display: flex;
			padding-bottom: 20px;
			.ops-btn {
				padding-left: 20px;
				@include base-button(80px);
			}
		}
		.pressure-tap-echarts-box {
			flex: 1;
			width: 100%;
			overflow: auto;
		}
	}
}

@import "~@/styles/variables/variables.scss";





















.base-title {
	height: 24px;
	line-height: 24px;
	font-family: 'Source Han Sans CN';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	display: flex;
	align-items: center;
	color: #333333;
	.text {
		margin-left: 12px;
	}
}
